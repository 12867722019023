import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/index"
import FOUNDERS_LOGO from "../images/founders-logo.svg"
import AME_LOGO from "../images/ame-logo.svg"
import CHERUBIC_LOGO from "../images/cherubic-logo.svg"
import COMBINATOR_LOGO from "../images/combinator-logo.svg"
import CRANE_LOGO from "../images/crane-logo.svg"
import ALL_LOGOS from "../images/all-logos.svg"
import MISSION_DESKTOP from "../images/mission-image-desktop.jpg"
import HISTORY_DESKTOP from "../images/history-image-desktop.jpg"
import Data from "../data/data"

import style from "./about.module.css"
const AboutPage = () => {
  return (
    <>
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{Data.about.title}</title>
          <meta name="description" content={Data.metaData.description.about} />
          <link
            href="https://fonts.googleapis.com/css?family=Raleway:700&display=swap"
            rel="stylesheet"
          />
        </Helmet>

        {/* Banner */}
        <div className={style.root}>
          <div className={style.about__banner}>
            <div className="container">
              <div className={`row ${style.customRow}`}>
                <div className="col s12">
                  <div className={style.about__bannerContent}>
                    <h1 className={style.about__heading}>
                      {Data.about.heading}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mission */}
        <div className={style.mission}>
          <div className={style.mission__banner}>
            <div className="container">
              <div className={`row ${style.customRow}`}>
                <div className="col s12">
                  <div className={style.mission__bannerContent}>
                    <h1 className={style.mission__heading}>
                      {Data.about.missionHeading}
                    </h1>
                    <p className={style.mission__textCopyBold}>
                      {Data.about.missionSubHeading}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mission Image */}
        <div className={style.mission__image}></div>

        {/* Mission Text*/}
        <div className={style.mission__text}>
          <div className="container">
            <div className={`row ${style.customRow}`}>
              <div className="col s12">
                <div className={style.mission__textContent}>
                  <p className={style.mission__textCopy}>
                    {Data.about.missionTextParagraphOne}
                  </p>

                  <p className={style.mission__textCopy}>
                    {Data.about.missionTextParagraphTwo}
                  </p>

                  <p className={style.mission__textCopy}>
                    {Data.about.missionTextParagraphThree}
                  </p>

                  <p className={style.mission__textCopy}>
                    <span className={style.mission__textCopyBold}>
                      {Data.about.missionTextParagraphFour_1}
                    </span>{" "}
                    {Data.about.missionTextParagraphFour_2}
                  </p>

                  <p className={style.mission__textCopy}>
                    {Data.about.missionTextParagraphFive}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Desktop Mission Section */}
        <div className={style.mission__desktop}>
          <div className={style.mission__banner}>
            <div className="container">
              <div className={`row ${style.customRow}`}>
                <div className="col s12">
                  <div className={style.mission__desktopContent}>
                    <div className={`row ${style.customRow}`}>
                      <div className="col m6">
                        <h2 className={style.mission__desktopHeading}>
                          {Data.about.missionHeading}
                        </h2>
                        <h4 className={style.mission__desktopSubHeading}>
                          {Data.about.missionSubHeading}
                        </h4>
                        <div className={`row ${style.customRow}`}>
                          <div className="col s12">
                            <div className={style.mission__desktopTextWrap}>
                              <div
                                className={style.mission__desktopRectangle}
                              ></div>
                              <div
                                className={
                                  style.mission__desktopTextWrapContent
                                }
                              >
                                <p className={style.mission__desktopText}>
                                  {Data.about.missionTextParagraphOne}
                                </p>
                                <p className={style.mission__desktopText}>
                                  {Data.about.missionTextParagraphTwo}
                                </p>
                                <p className={style.mission__desktopText}>
                                  {Data.about.missionTextParagraphThree}
                                </p>
                                <p className={style.mission__desktopText}>
                                  <span
                                    className={style.mission__desktopTextBold}
                                  >
                                    {Data.about.missionTextParagraphFour_1}
                                  </span>{" "}
                                  {Data.about.missionTextParagraphFour_2}
                                </p>

                                <p className={style.mission__desktopText}>
                                  {Data.about.missionTextParagraphFive}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col m6">
                        <img
                          className={style.mission__desktopImage}
                          src={MISSION_DESKTOP}
                          alt="How it works"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* History Section */}

        {/* History Image */}
        <div className={style.history__image}></div>

        {/* History Text */}
        <div className={style.history__text}>
          <div className="container">
            <div className={`row ${style.customRow}`}>
              <div className="col s12">
                <h4 className={style.history__textHeading}>
                  {Data.about.historyHeading}
                </h4>
                <p className={style.history__textCopyBold}>
                  {Data.about.historySubHeading}
                </p>

                <p className={style.history__textCopy}>
                  {Data.about.historyTextParagraphOne}
                </p>

                <p className={style.history__textCopy}>
                  {Data.about.historyTextParagraphTwo}
                </p>

                <p className={style.history__textCopy}>
                  {Data.about.historyTextParagraphThree}
                </p>

                <p className={style.history__textCopy}>
                  {Data.about.historyTextParagraphFour}
                </p>

                <p className={style.history__textCopy}>
                  {Data.about.historyTextParagraphFive}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* History Desktop Section*/}
        <div className={style.history__desktop}>
          <div className={style.history__banner}>
            <div className="container">
              <div className={`row ${style.customRow}`}>
                <div className="col s12">
                  <div className={style.history__desktopContent}>
                    <div className={`row ${style.customRow}`}>
                      <div className="col m6">
                        <img
                          className={style.history__desktopImage}
                          src={HISTORY_DESKTOP}
                          alt="How it works"
                        />
                      </div>
                      <div className="col m6">
                        <h2 className={style.history__desktopHeading}>
                          {Data.about.historyHeading}
                        </h2>
                        <h4 className={style.history__desktopSubHeading}>
                          {Data.about.historySubHeading}
                        </h4>
                        <div className={`row ${style.customRow}`}>
                          <div className="col s12">
                            <div className={style.history_desktopTextWrap}>
                              <div
                                className={style.history__desktopRectangle}
                              ></div>
                              <div
                                className={
                                  style.history__desktopTextWrapContent
                                }
                              >
                                <p className={style.history__desktopText}>
                                  {Data.about.historyTextParagraphOne}
                                </p>
                                <p className={style.history__desktopText}>
                                  {Data.about.historyTextParagraphTwo}
                                </p>
                                <p className={style.history__desktopText}>
                                  {Data.about.historyTextParagraphThree}
                                </p>
                                <p className={style.history__desktopText}>
                                  {Data.about.historyTextParagraphFour}
                                </p>

                                <p className={style.history__desktopText}>
                                  {Data.about.historyTextParagraphFive}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Investment Section */}
        <div className={style.investment}>
          <div className="container">
            <div className={`row ${style.customRow}`}>
              <div className="col s12">
                <div className={style.investment__content}>
                  <h4 className={style.investment__heading}>
                    {Data.about.logoHeading}
                  </h4>
                  <div className={style.investment__logos}>
                    <div className={style.investment__logo}>
                      <img
                        src={FOUNDERS_LOGO}
                        alt="Founders logo"
                        className={style.founders}
                      />
                    </div>
                    <div className={style.investment__logo}>
                      <img
                        src={AME_LOGO}
                        alt="AME logo"
                        className={style.ame}
                      />
                    </div>
                    <div className={style.investment__logo}>
                      <img
                        src={CHERUBIC_LOGO}
                        alt="AME logo"
                        className={style.cherubic}
                      />
                    </div>
                    <div className={style.investment__logo}>
                      <img
                        src={COMBINATOR_LOGO}
                        alt="Combinator logo"
                        className={style.combinator}
                      />
                    </div>
                    <div className={style.investment__logo}>
                      <img
                        src={CRANE_LOGO}
                        alt="Crane logo"
                        className={style.crane}
                      />
                    </div>
                  </div>
                  <div className={style.investment__logosDesktop}>
                    <img
                      src={ALL_LOGOS}
                      alt="Investment logos"
                      className={style.allLogos}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Contact Section */}
        <div className={style.contactMap}>
          <div className={style.contact}>
            <div className={`container ${style.customContainer}`}>
              <div className={`row ${style.customRowLargeScreen}`}>
                <div className="col s12">
                  <div className={style.contact__content}>
                    <h4 className={style.contact__heading}>Contact</h4>
                    <address className={style.contact__address}>
                      25 Finsbury Circus,
                      <br />
                      London, <br />
                      EC2M 7EE, <br />
                      UK
                    </address>
                    <div className={style.contact__number}>
                      <a
                        href="tel:+442032397951"
                        className={style.contact__numberLink}
                      >
                        +44 (0)20 3239 7951
                      </a>
                      <a
                        href="mailto:contact@shipamax.com"
                        className={style.contact__numberLink}
                      >
                        contact@shipamax.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.map} id="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9931.130998156985!2d-0.085917!3d51.5172016!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf03b10b91e75d51d!2sShipamax+Ltd.!5e0!3m2!1sen!2suk!4v1563236193418!5m2!1sen!2suk"
              frameBorder={0}
              allowFullScreen
              margin={0}
              className={style.map__content}
              title={"shipmap"}
            />
          </div>
        </div>
      </Layout>
    </>
  )
}

AboutPage.propTypes = {}

export default AboutPage
