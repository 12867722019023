export default {
  metaData: {
    siteTitle: "Shipamax | Specialised supply chain data extraction platform",
    description: {
      homepage:
        "Shipamax helps Freight Forwarders, Chartering Teams and Shipbrokers cut time spent on low value tasks and spearhead their digital transformation with a specialised data extraction platform. ",
      freightForwarders:
        "Automate data entry to reduce admin cost and turnaround time, plug and play into your existing infrastructure and lay the groundwork for your digital transformation.",
      charteringTeams:
        "Make more time for high impact tasks that drive profit. Identify and act on the best opportunities. Simple uncluttered tool for friction-free experience.",
      shipBokers:
        "Drive loyalty with more time to deliver advice and win more fixtures with faster response times. Get started in 30 minutes.",
      about: "Our Mission, our History and where to find us.",
      contact: "Get in touch! We'd love to hear from you.",
      login: "Access your Shipamax account.",
      forgot: "Recover your Shipamax account.",
    },
  },
  indexPage: {},
  header: {
    title: "Shipamax",
  },
  homePage: {
    title: "Shipamax | Turn supply chain documents into data",
    heading: "Accelerating the next generation of supply chain",
    subHeading: `Cut time spent on low-value tasks and spearhead 
    your digital transformation with a specialised data
    extraction platform`,
    requestDemoButton: "Request a demo",
    logoHeading: "Join industry leaders driving transformation with Shipamax",
  },
  freightForwarders: {
    title:
      "Shipamax for Freight Forwarders | Turn Documents into structured data",
    pageTitle: "Freight Forwarders",
    heading: `Turn documents into structured data`,
    subHeading: `Deliver immediate commercial results with a plug-and-play data extraction platform specialized for the supply chain industry`,
    automateSectionHeading:
      "Automate data entry to reduce admin costs and turnaround time",
    automateSectionParagraphOne:
      "Shift teams away from manual data entry to high-impact activities",
    automateSectionParagraphTwo:
      "Reduce turnaround time for a better customer experience",
    automateSectionParagraphThree:
      "Improve data quality by reducing human error",
    infrastructureSectionHeading:
      "Plug directly into your existing infrastructure and processes for immediate results",
    infrastructureSectionParagraphOne:
      "Syncs with any email server to process documents as soon as they hit your inbox",
    infrastructureSectionParagraphTwo:
      "Machine learning based processing - no templates or rules to maintain",
    infrastructureSectionParagraphThree:
      "Minimise change management with an email plugin for data validation, designed to fit straight into your existing workflow and processes",
    infrastructureSectionParagraphFour:
      "CargowiseOne integration or Open API to sync data into your ERP",
    groundworkSectionHeading:
      "Lay the groundwork to power your digital transformation",
    groundworkSectionParagraphOne:
      "Industry-leading machine learning, specialised in logistics to ensure high accuracy and broad document coverage",
    groundworkSectionParagraphTwo:
      "Enable new applications and services to be built by translating data into a single standard",
    groundworkSectionParagraphThree:
      "Test and verify your ideas quickly with a developer friendly API",
    supportedDocumentsHeading: "Supported documents",
    liveModuleHeading: "Live",
    liveModuleTextRowOne: "Bill of Ladings",
    liveModuleTextRowTwo: "Delivery Order",
    liveModuleBtnLink: "contact",
    liveModuleBtnLabel: "Get a demo",
    betaModuleHeading: "Beta",
    betaModuleTextRowOne: "Carrier Invoices",
    betaModuleTextRowTwo: "Arrival Notice",
    betaModuleBtnLink: "contact",
    betaModuleBtnLabel: "Join the beta program",
    alphaModuleHeading: "Alpha",
    alphaModuleTextRowOne: "Commercial Invoices",
    alphaModuleTextRowTwo: "Packing Lists",
    alphaModuleBtnLink: "contact",
    alphaModuleBtnLabel: "Join the alpha program",
    howItWorksHeading: "How it works",
  },
  charteringTeams: {
    title:
      "Shipamax for Chartering Teams | Clutter free Chartering workflow that connects to your existing applications",
    pageTitle: "Chartering Teams",
    heading: `Clutter-free chartering workflow that connects to your existing applications`,
    subHeading: `Stay #1 in tomorrow's market. Connect your data to streamline processes, improve market predictions, and ensure long-term reliability and flexibility for your clients.`,
    strategicSectionHeading:
      "Strategic infrastructure that drives commercial results today",
    strategicSectionParagraphOne:
      "Shift 2 hours a day to high-impact activities by reducing time spent in email by 85%",
    strategicSectionParagraphTwo:
      "Increase team efficiency with a tool designed to minimize friction between tasks",
    strategicSectionParagraphThree:
      "Streamline processes and unlock the value of your data with flexible and extendable APIs",
    goalsSectionHeading: "A smooth path to achieving your strategic goals",
    goalsSectionParagraphOne:
      "Core feature parity against existing solutions to minimise chances of rejection",
    goalsSectionParagraphTwo:
      "Ramp up quickly with an intuitive user experience, responsive navigation and rapid in-app support",
    goalsSectionParagraphThree:
      "Grow trust and confidence with 99.9% uptime & 99% accuracy, machine-only data processing",
    deliverySectionHeading:
      "Ensure long-term delivery with a partner, not a vendor",
    deliverySectionParagraphOne:
      "Accelerate your digitalisation strategy with a trusted advisor dedicated to making technology work for your needs",
    deliverySectionParagraphTwo:
      "Input into product roadmap decisions to ensure your core infrastructure doesn't go stale",
    deliverySectionParagraphThree:
      "Always be ahead of the curve with a team that strives for a focused, best-of-breed approach",
    benefitsSectionHeading: "Chartering team benefits",
    benefitsModuleOneText: "More time for high-impact tasks that drive profit",
    benefitsModuleTwoText: "Identify and act faster on the best opportunities",
    benefitsModuleThreeText:
      "Simple, uncluttered tool for a frustration-free experience",
  },
  shipBrokers: {
    title:
      "Shipamax for Shipbrokers | Simple to use, automatic positions lists",
    pageTitle: "Shipbrokers",
    heading: `Simple-to-use, automatic position lists`,
    subHeading: `Stay first choice with more time to advise, faster response times for your clients and an instant view of the market.`,
    loyaltyHeading: "Drive loyalty with more time to deliver advice",
    loyaltyParagraphOne:
      "Reduce email-admin fatigue with an automatic position list",
    loyaltyParagraphTwo:
      "Free-up 2 hours a day to deliver consistent, expert value to your clients",
    loyaltyParagraphThree:
      "Immediately read the market with instant data at your fingertips",
    fixtureHeading: "Win more fixtures with faster response times",
    fixtureParagraphOne:
      "Be the first to respond to clients with a one-click shortlist of suitable candidates, ranked by ETA",
    fixtureParagraphTwo:
      "Quickly guide clients with rate estimates using a simple, integrated voyage estimator",
    fixtureParagraphThree:
      "Minimise duplicate work by collaborating with your team on rates, fixtures & preferences",
    startedHeading: "Get started in 30 mins",
    startedParagraphOne:
      "Simple, uncluttered tool for a frustration-free experience",
    startedParagraphTwo:
      "Fast and intuitive layout, designed for multi-tasking",
    startedParagraphThree: "Personal onboarding & rapid in-app support",
    coreFeaturesSectionHeading: "Core Features",
    cargoHeading: "Automatic Cargo and Position Lists",
    cargoParagraphOne:
      "Save searches for key clients or to monitor particular markets",
    cargoParagraphTwo:
      "Search based on size, location, dates, sender and much more",
    cargoParagraphThree:
      "Live updates as new emails hit your inbox",
    voyageHeading: "Voyage Estimator",
    voyageParagraphOne:
      "Perform one-click simple estimates or go into more detail",
    voyageParagraphTwo: "Easily compare multiple options side by side",
    voyageParagraphThree: "Optimized for speed and usability",
    shortlistingHeading: "Voyage Estimator",
    shortlistingParagraphOne:
      "One-click to filter vessels for your Cargo based on laycan, size & location and vise versa",
    shortlistingParagraphTwo: "Ordered by ETA",
    parserHeading: "Chat Parser",
    parserParagraphOne:
      "Combine data from all your sources by copying and pasting Cargos or Positions from Skype, ICE, Whatsapp directly into Shipamax",
    parserParagraphTwo:
      "Shipamax instantly extracts and structures the data into your internal workspace",
    exportHeading: "Export Lists to Email",
    exportParagraphOne: "Export any of your lists ready to be emailed",
    exportParagraphTwo: "Design your own export templates",
    peaceOfMindHeading: "Peace of mind for your IT support team",
    peaceOfMindModuleOneText:
      "5 minutes to set up and no internal maintenance needed",
    peaceOfMindModuleTwoText: "Syncs with your current email provider",
    peaceOfMindModuleThreeText:
      "Military-grade email encryption hosted on AWS in Ireland - trusted by banks & national defence organisations globally",
  },
  about: {
    title: "Shipamax | About us",
    heading: `About Us`,
    subHeading: ``,
    missionHeading: "Mission",
    missionSubHeading: `Consumer behaviour is changing dramatically - shaking up retail, food production, and even the sources of energy used in manufacturing. Behind the scenes, supply chain is more competitive than ever as new entrants have scrambled to take advantage of the changing landscape.`,
    missionTextParagraphOne: `As consumer norms shift, there have been some casualties but others have thrived and are growing at a rapid pace - demanding more from their logistics providers to ensure they remain competitive.`,
    missionTextParagraphTwo: `In response, logistics companies & teams are ramping up on technology to support their clients -  old & new - in this modern world. Whilst software has been improving the way we work for decades, the reliance on good, old-fashioned email isn’t changing anytime soon.`,
    missionTextParagraphThree: `As supply chain companies strive for increased optimization, the burden of unstructured data is becoming more apparent, preventing businesses from opening up new revenue streams, improving market and pricing predictions, or making continual efficiency gains.`,
    missionTextParagraphFour_1: `Our mission is to accelerate the next generation of supply chain`,
    missionTextParagraphFour_2: `with specialised data extraction. Our product connects to any email inbox or unstructured data source and automatically extracts data from emails & attachments in real-time - outputting a clean, structured feed via API.`,
    missionTextParagraphFive: `We enable teams to shift towards high-value tasks and organisations to build for the future.`,
    historyHeading: "History",
    historySubHeading:
      "Shipamax was founded in 2016 in London, UK after experiencing the inefficiencies involved in  transporting bulk products: clunky, confusing software, spreadsheets, and thousands of emails each day.",
    historyTextParagraphOne: `In 2017, we joined Y Combinator to accelerate our journey. After an intense few months of building product and delighting early users, we won the financial support of a number of leading technology investors, raising $2.5 million to help us grow.`,
    historyTextParagraphTwo: `Initially, Shipamax focused on serving customers in bulk and tanker shipping - steadily winning over some of the largest global logistics organisations as clients and long-term partners in development.`,
    historyTextParagraphThree: `Through serving clients of all sizes - from one-person brokers to the largest shippers in the world - we noticed a common thread. The technology we’d built for extracting data from the thousands of emails received each day freed up time, allowing teams to cut the time they spend on monotonous admin, and increase the time spent on driving profit. Organisations could finally take control of their data, build the workflows and extract the insights that their existing systems had prevented them from doing.`,
    historyTextParagraphFour: `In 2018, we began working with freight forwarding clients, adapting our platform to reach a high level of accuracy for the unique requirements of this industry. With our experience understanding context in unstructured bulk and tanker shipping data, we support freight forwarders by extracting data within emails on ocean container shipments - allowing them to also gain control of their data, build automated workflows, and extract unique insights.`,
    historyTextParagraphFive: `A team of Data Scientists, Engineers & Commercial Support based in London, Shipamax is dedicated to delivering commercial results with expert customer success support and a commitment to technology innovation and excellence.`,
    logoHeading:
      "We’re proud to be backed by world leaders in B2B enterprise technology investment",
  },
  contact: {
    title: "Shipamax | Contact us",
    heading: `Contact Form`,
    subHeading: ``,
  },
  login: {
    title: "Shipamax | Log In",
  },
  forgot: {
    title: "Shipamax | Forgot Password",
  },
}
